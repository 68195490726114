.webcam {
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  object-fit: cover;
  opacity: 0.5;
}
