.ui {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  z-index: 3;

  color: #25ff00;
  font-size: 28px;
  line-height: 36px;
}

.ui a {
  white-space: nowrap;
  color: #25ff00;
  text-decoration: none;
}
.ui a:hover {
  text-decoration: underline;
}
.ui a[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.ui a[disabled]:hover {
  text-decoration: none;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.header h2,
.header h3 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}

.main {
  width: 100%;
  min-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.main h1 {
  font-weight: 400;
  font-size: 80px;
  line-height: 80px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.info {
  padding-right: 20px;
  overflow-x: hidden;
}
.action {
  flex-shrink: 0;
}
.homeLink {
  display: inline-block;
}
.worldLink {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .ui {
    padding: 10px 20px;
  }
  .ui,
  .header h2,
  .header h3 {
    font-size: 28px;
    line-height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .header,
  .main {
    flex-wrap: wrap;
  }
  .main {
    flex-direction: column-reverse;
  }
  .header > *,
  .main > * {
    width: 100%;
  }
  .main h1 {
    font-size: 48px;
    line-height: 56px;
  }
  .info {
    padding-right: 0px;
  }
  .ui a {
    color: #fff;
  }
  .ui a:focus {
    text-decoration: none;
  }
}

@media screen and (max-width: 384px) {
  .ui {
    font-size: 24px;
    line-height: 30px;
  }
  .ui,
  .header h2,
  .header h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
