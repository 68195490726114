.main {
  width: 100%;
  min-width: 0;
  padding: 20px 50px;
}

.topBar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.brand {
  color: #25ff00;
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.link {
  color: #25ff00;
  text-decoration: none;
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}

.manifesto {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
}

.image {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  margin-bottom: 10px;
}

.row {
  margin-top: 40px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
}

.col6 {
  width: 48%;
}

.paragraph {
  margin-top: 0;
  margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
  .topBar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .main {
    padding: 10px 20px;
  }

  .row {
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 0px;
  }

  .col6 {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 384px) {
  .manifesto,
  .link,
  .brand {
    font-size: 24px;
    line-height: 30px;
  }
  .row {
    flex-wrap: wrap;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .col6 {
    padding: 20px 0px;
    width: 100%;
  }
}
